<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col justify-center w-full">
    <nuxt-link to="/">
      <img src="public/layoutParts/header.png" alt="" />
    </nuxt-link>
    <div
      class="flex justify-between lg:justify-end bg-moonlight-gray py-2 px-4"
    >
      <div
        class="lg:hidden aspect-square border-moonlight-gold text-moonlight-gold border-2 text-3xl rounded-xl flex items-center justify-center cursor-pointer"
        @click="isMobileSidebarOpen = true"
      >
        ✦
      </div>
      <div>
        <Menu v-if="userWitch" as="div" class="relative">
          <div class="flex gap-3">
            <MenuButton
              class="flex text-sm rounded-full border-moonlight-gold border-2"
            >
              <StructureNavbarWitch />
            </MenuButton>
            <MenuButton
              class="flex text-sm rounded-full border-moonlight-gold border-2"
            >
              <StructureNavbarWitchDress />
            </MenuButton>
            <StructureNavbarFairies />
            <MenuButton
              class="flex text-sm rounded-full border-moonlight-gold border-2"
            >
              <StructureNavbarCloset />
            </MenuButton>
            <MenuButton
              class="flex text-sm rounded-full border-moonlight-gold border-2"
            >
              <StructureNavbarInventory />
            </MenuButton>
            <MenuButton>
              <login />
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white bg-opacity-[30%] text-slate-500 ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <MenuItem v-slot="{ active }">
                <router-link
                  active-class="text-gray-200"
                  :to="`/witch/${userWitch?.id}/profile`"
                  :class="{ 'text-gray-500': active }"
                  class="block px-4 py-2 text-sm text-gray-400"
                  >Dein Profil
                </router-link>
              </MenuItem>
              <!--                  <MenuItem v-slot="{ active }">-->
              <!--                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Einstellungen</a>-->
              <!--                  </MenuItem>-->
              <MenuItem v-slot="{ active }">
                <a
                  href="#"
                  :class="{ 'text-gray-500': active }"
                  class="block px-4 py-2 text-sm text-gray-400"
                  @click="logout"
                  >Ausloggen</a
                >
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <router-link
                  active-class="text-gray-200"
                  to="/user/news"
                  :class="{ 'text-gray-500': active }"
                  class="block px-4 py-2 text-sm text-gray-400"
                  >News
                </router-link>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
        <login v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import Login from "@/pages/user/Login.vue";
import useUserStore from "~/stores/useUserStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { userWitch } = storeToRefs(userStore);
const pocketBaseStore = usePocketBase();
const { logout } = pocketBaseStore;
const { isMobileSidebarOpen } = storeToRefs(useSidebarStore());
</script>

<style lang="css"></style>
