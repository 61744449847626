<template>
  <div class="bg-opacity-5 rounded-full">
    <NuxtLink :to="`/witch/${userWitch?.id}/profile`">
      <CommonAvatarWitch v-if="userWitch" :witch-id="userWitch.id" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const { userWitch } = storeToRefs(useUserStore());
</script>
