<template>
  <div class="bg-opacity-5 rounded-full">
    <NuxtLink :to="`/witch/${userWitch?.id}/school`">
      <CommonAvatarWitchSchool
        v-if="userWitch"
        :witch-id="userWitch.id"
        is-dress
      />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const { userWitch } = storeToRefs(useUserStore());
</script>
