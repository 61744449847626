<template>
  <NuxtLink to="/closet">
    <div
      class="bg-opacity-5 rounded-full flex justify-center items-center h-16 overflow-clip aspect-square"
    >
      <img
        class="object-cover h-12"
        style="object-position: 10% 4px"
        src="public/layoutParts/icon_kleiderschrank.png"
        alt=""
      >
    </div>
  </NuxtLink>
</template>
